/* eslint no-console:0 */
// This is the entrypoint for the `sohogrand` child theme. The files
// imported here will be included on every page of the sohogrand website.
//
// To reference this file, add <%= javascript_pack_tag 'sohogrand' %> to the appropriate
// layout file, like frontend/sohogrand/layouts/application.haml

import 'shared/assets/css'
import 'hotels/assets/css'
import './css'
import './javascript'
import 'hotels/components'
import 'shared/components'
